<template>
    <section class="count-up-box">
        <div class="count-up-title">{{basic.textConfig[objkey]}}</div>
        <div :id="id" class="count-up-nums"></div>
        <div class="count-up-subtitle">{{basic.unit}}</div>
    </section>
</template>

<script setup>
const props = defineProps({
    data: Object,
    objkey: String,
});
const { objkey } = props;
const { data } = toRefs(props);

const id = 'countup-' + objkey;
const basic = reactive({
    countup: null,
    textConfig: {
        'daily': '日',
        'month': '月',
        'year': '年',
        'total': '累计',
    },
    unit: '',
})
const fn = {
    init(info) {
        const count = parseFloat(info[objkey]);
        basic.unit = info[objkey]?.replace(count, '');
        if (!basic.countup) {
            basic.countup = new countUp.CountUp(id, count, {
                useGrouping: true,
                decimalPlaces: 3,
            });
            basic.countup.start();
        } else {
            fn.updateCount(count);
        }
    },
    updateCount(val) {
        basic.countup.update(val);
    }
}
watch(data, val => {
    val && fn.init(val);
}, {
    deep: true,
});
</script>
<style lang="scss" scoped>
.count-up-box {
    width: 14rem;
    height: 12rem;
    border-radius: 0.2rem;
    background-color: rgba(197, 211, 221, 0.245);
    padding: 1rem;
}
.count-up-title {
    color: rgb(210, 210, 210);
    font-size: 2rem;
    text-align: center;
}
.count-up-subtitle {
    color: rgb(210, 210, 210);
    font-size: 1.4rem;
    text-align: center;
}

.count-up-nums {
    color: white;
    font-size: 3.6rem;
    text-align: center;
}
</style>