<template>
    <div class="equipment-status clearfix">
        <template v-for="item in basic.list">
            <div class="float-left info common-inset-shadow-dark cursor-pointer" @click="item.value > 0 && fn.openDialogHandle(item)">
                <span class="info-title color-green font-14">{{ item.name }}设备</span>
                <span class="info-content font-16 color-white underline">{{ item.value }}</span>
            </div>
        </template>
    </div>
    <tricomponent-dialog :show="basic.dialogShow" width="50%" :title="basic.dialogData.name + '设备'" @onCloseDialog="fn.closeDialogHandle">
        <!-- <tricomponent-table :tableData="basic.dialogData.list" :tableColumn="basic.tableColumn" /> -->
        <el-row :gutter="16">
            <el-col :span="12" class="text-center font-16 font-bolder">设备名称</el-col>
            <el-col :span="12" class="text-center font-16 font-bolder">最近上传时间</el-col>
        </el-row>
        <el-row :gutter="16" v-for="item in basic.dialogData.list">
            <el-col :span="12" class="text-center">{{ item.name }}</el-col>
            <el-col :span="12" class="text-center">{{ item.time }}</el-col>
        </el-row>
    </tricomponent-dialog>
</template>

<script setup>
const props = defineProps({
    data: Object,
});
const { data } = toRefs(props);
const basic = reactive({
    list: [],
    dialogShow: false,
    dialogData: {},
    tableColumn: [
        {
            label: '名称',
            key: 'name'
        },
        {
            label: '最近上次时间',
            key: 'time',
        }
    ]
})
const fn = {
    openDialogHandle(info) {
        basic.dialogShow = true;
        basic.dialogData = info;
    },
    closeDialogHandle() {
        basic.dialogShow = false;
        basic.dialogData = {};
    },
}
watch(data, val => {
    if (val?.equipment?.length > 0) {
        basic.list = val.equipment;
    }
}, {
    deep: true,
    immediate: true,
})
</script>

<style lang="scss" scoped>
.equipment-status {
    position: absolute;
    bottom: 100%;
    z-index: 10;
    margin-bottom: 1.6rem;
}

.el-row {
    &:first-child {
        border-top: 1px solid #ffffff;
    }
    // &:not(:last-child) {
        border-bottom: 1px solid #ffffff;
        // border-left: 1px solid #ffffff;
        // border-right: 1px solid #ffffff;
    // }

    .el-col {
        text-align: center;
        padding: 0.5rem 0;
        &:first-child {
            border-right: 1px solid #ffffff;
        }
    }
}

.info {
    padding: 0 1rem;

    &:not(:last-child) {
        // border-right: 1px solid #ffffff;
        margin-right: 1rem;
    }

    &-content {
        padding-left: 0.4rem;
    }
}
</style>