<template>
    <section class="height-full" v-loading="basic.loading">
        <section class="text-right">
            <el-date-picker
                v-if="data.click.date == 'date'"
                v-model="basic.date"
                type="date"
                placeholder="日期"
                value-format="YYYY-MM-DD"
            />
            <el-date-picker
                v-else-if="date.click.date == 'daterange'"
                v-model="basic.daterange"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="x"
            />
            <el-button style="margin-left:10px;" @click="fn.init(summary)">查询</el-button>
        </section>
        <div :id="id" ref="box" style="height:400px" class="relative"></div>
    </section>
</template>
<script setup>
import { fetchSummaryChart } from '~/utils/api/solar/monitor';
import { useOwnerStore } from '~/store/owner';
import { useWebsocketStore } from '~/store/socket';
import { xAxisHandle, lineHandle, yAxisHandle, tooltipHandle } from '~/utils/tools/echart-config';
import helper from '~/utils/tools/helper';

const ownerStore = useOwnerStore();

const props = defineProps({
    data: Object,
    summary: String,
})
const { data, summary } = props;

const id = 'popup-line-chart-' + Math.floor(Math.random() * 100000);
const box = ref(null);
const basic = reactive({
    loading: true,
    chart: null,
    date: dayjs().format('YYYY-MM-DD'),
    daterange: [],
    chartConfig: {
        temperature: {
            name: '温度\n(℃)'
        }
    }
})
const fn = {
    createChart(info) {
        const options = {
            xAxis: xAxisHandle({
                data: info.map(item => item.x),
                options: {
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: "rgba(255,255,255,0.4)",
                        }
                    }
                }
            }),
            yAxis: [
                yAxisHandle({
                    name: basic.chartConfig[summary]?.name,
                    options: {
                        min: 'dataMin'
                    }
                }),
            ],
            series: [
                lineHandle({
                    name: '温度',
                    data: info.map(item => item.y),
                    options: {
                        itemStyle: {
                            color: "#12ba12",
                        }
                    }
                }),
            ],
            dataZoom: [
                {
                    show: true,
                    type: 'slider',
                    brushSelect: false,
                    height: 20,
                    throttle: 300,
                    handleStyle: {
                        borderWidth: 3,
                    },
                    textStyle: {
                        color: '#ffffff',
                    }
                },
            ],
            tooltip: tooltipHandle({unit: '℃'}),
        };

        basic.chart.setOption(options)
    },
    async init(type) {
        basic.loading = true;
        const params = {
            ownerId: ownerStore.ownerId,
        };
        if (data.click.date) {
            params.date = basic.date;
        } else if (data.click.daterange && basic.daterange.length > 1) {
            params.startTime = basic.daterange[0];
            params.stopTime = basic.daterange[1];
        }
        const res = await fetchSummaryChart(type, params);
        if (res.code == 0) {
            fn.initChart(res.data);
        }
        basic.loading = false;
    },
    async initChart(info) {
        basic.chart = markRaw(echarts.init(box.value));
        if (info) {
            fn.createChart(info);
        }
    },
    destroyChart() {
        basic.chart && basic.chart.dispose();
    }
}
const debounceHandle = helper.debounce(() => {
    basic.chart && basic.chart.resize();
}, 200);
onMounted(() => {
    fn.init(summary);
    window.addEventListener('resize', debounceHandle);
})
onUnmounted(() => {
    fn.destroyChart();
    window.removeEventListener('resize', debounceHandle);
})
</script>
<style lang="scss" scoped>
.common-inset-shadow-dark {
    padding: 20px;
}
</style>