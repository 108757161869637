<template>
    <section class="screen flex height-full">
        <section class="screen-left height-full" style="background-color: red;">
            <section class="screen-left-box screen-left-box-1">
                
            </section>
            <section class="screen-left-box screen-left-box-2">
                
            </section>
            <section class="screen-left-box screen-left-box-3">
                
            </section>
            <section class="screen-left-box screen-left-box-4">
                
            </section>
        </section>
        <section class="screen-center flex-direction-column flex-dynamic-full">
            <section class="center-top" style="background-color: green;">

            </section>
            <section class="center-map flex-dynamic-full">
                <Map />
            </section>
            <section class="center-bottom" style="background-color: green;">
            
            </section>
        </section>
        <section class="screen-right" style="background-color: red;">
            <section class="screen-right-box screen-right-box-1">
                
            </section>
            <section class="screen-right-box screen-right-box-2">
                
            </section>
            <section class="screen-right-box screen-right-box-3">
                
            </section>
            <section class="screen-right-box screen-right-box-4">
                
            </section>

        </section>
    </section>
</template>
<style lang="scss" scoped>

.screen {
    &-right,
    &-left {
        width: 20%;
        &-box:not(:last-child) {
            border-bottom: 1px solid #ffffff;
        }
    }
    &-left-box {
        &-1 {
            height: 20%;
        }
        &-2 {
            height: 30%;
        }
        &-3 {
            height: 30%;
        }
        &-4 {
            height: 20%;
        }
    }
    &-right-box {
        &-1 {
            height: 20%;
        }
        &-2 {
            height: 30%;
        }
        &-3 {
            height: 30%;
        }
        &-4 {
            height: 20%;
        }
    }
    &-center {
        position: relative;
    }
    .center-top {
        height: 20%;
        width: 100%;
    }
    .center-bottom {
        height: 20%;
        width: 100%;
    }
    .center-map {
        width: 100%;
    }
}
</style>
<script>
import Map from './map.vue';
export default {
    components: {
        Map,
    }
}
</script>
<script setup>
import { useUserStore } from "~~/store/user";
import { useOwnerStore } from '~/store/owner';
const ownerStore = useOwnerStore();
const userStore = useUserStore();
const basic = reactive({
    loading: false,
    isEmpty: false,
    emptyText: '',
    data: {},
})
const fn = reactive({
    init() {
        fn.fetchAllData();
    },
    fetchAllData() {
        console.log('获取数据集合')
    },
});
watch(() => ownerStore.ownerId, _ => {
    fn.init();
});
onMounted(() => {

})
</script>