<template>
    <div class="common-inset-shadow-dark equipment-chart clearfix">
        <section class="text-center color-white font-16" style="padding-top:1.5rem;height:1.8rem">设备运行状态</section>
        <section class="flex" style="height:19rem;padding-top:1.5rem">
            <div
                element-loading-background="transparent"
                ref="box"
                class="height-full"
                style="width:50%"
            ></div>
            <section class="flex-hor-center flex-direction-column" style="padding:0 1rem 0 2rem">
                <div v-for="item in basic.list" class="info cursor-pointer" :style="`background-color:${basic.colorConfig[item.name]}`" @click="item.value > 0 && fn.openDialogHandle(item)">
                    <span class="info-title color-white font-14">{{ item.name }}</span>
                    <span class="info-content font-16 color-white underline">{{ item.value }}</span>
                </div>
            </section>
        </section>
    </div>
    <tricomponent-dialog :show="basic.dialogShow" width="50%" :title="basic.dialogData.name + '设备'"
        @onCloseDialog="fn.closeDialogHandle">
        <!-- <tricomponent-table :tableData="basic.dialogData.list" :tableColumn="basic.tableColumn" /> -->
        <el-row :gutter="16">
            <el-col :span="12" class="text-center font-16 font-bolder">设备名称</el-col>
            <el-col :span="12" class="text-center font-16 font-bolder">最近上传时间</el-col>
        </el-row>
        <el-row :gutter="16" v-for="item in basic.dialogData.list">
            <el-col :span="12" class="text-center">{{ item.name }}</el-col>
            <el-col :span="12" class="text-center">{{ item.time }}</el-col>
        </el-row>
    </tricomponent-dialog>
</template>

<script setup>
const props = defineProps({
    data: Object,
});
const { data } = toRefs(props);
const box = ref(null);
const basic = reactive({
    chart: null,
    list: [],
    dialogShow: false,
    dialogData: {},
    tableColumn: [
        {
            label: '名称',
            key: 'name'
        },
        {
            label: '最近上次时间',
            key: 'time',
        }
    ],
    colorConfig: {
        '正常': 'rgb(120,218,177)',
        '故障': 'rgb(233,107,106)',
        '离线': 'rgb(210,210,210)',
        '异常': 'rgb(244,197,69)'
    }
})
const fn = {
    createChart() {
        if (!basic.chart) {
            basic.chart && basic.chart.dispose();
            basic.chart = markRaw(echarts.init(box.value));
        }
        const option = {
            tooltip: {
                show: true,
            },
            animation: false,
            series: [
                {
                    type: 'pie',
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: basic.list.map(item => {
                        return {
                            value: item.value,
                            name: item.name,
                            itemStyle: {
                                color: basic.colorConfig[item.name]
                            }
                        }
                    })
                }
            ]
        };
        basic.chart.setOption(option);
    },
    openDialogHandle(info) {
        basic.dialogShow = true;
        basic.dialogData = info;
    },
    closeDialogHandle() {
        basic.dialogShow = false;
        basic.dialogData = {};
    },
}
watch(data, val => {
    if (val?.equipment?.length > 0) {
        basic.list = val.equipment;
        fn.createChart();
    }
}, {
    deep: true,
    immediate: true,
})
onMounted(() => {
    fn.createChart();
})
</script>

<style lang="scss" scoped>
.el-row {
    &:first-child {
        border-top: 1px solid #ffffff;
    }

    // &:not(:last-child) {
    border-bottom: 1px solid #ffffff;
    // border-left: 1px solid #ffffff;
    // border-right: 1px solid #ffffff;
    // }

    .el-col {
        text-align: center;
        padding: 0.5rem 0;

        &:first-child {
            border-right: 1px solid #ffffff;
        }
    }
}

.info {
    padding: 0 1rem;
    border-radius:0.2rem;
    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &-content {
        padding-left: 0.4rem;
    }
}</style>