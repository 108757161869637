<template>
    <section class="rank-column" :class="['rank-column-' + rank_column]" v-loading="basic.loading">
        <section class="rank-box height-full flex-direction-column common-inset-shadow-dark scroll-hidden">
            <section class="rank-select">
                <div class="text text-center font-16 color-white underline">{{ basic.rankTypeName }}</div>
                <section class="select-box">
                    <el-select v-model="basic.rankType" @change="fn.rankTypeChange">
                        <el-option v-for="item in basic.rankTypeList" :key="item" :value="item"
                            :label="Columns[item].rank" />
                    </el-select>
                </section>
                <!-- <div class="reverse-btn font-12 process-btn" @click="fn.sortHandle()">倒序</div> -->
            </section>
            <section class="rank-list scroll-hidden flex-dynamic-full">
                <section class=" height-full scroll-auto-y">
                    <template v-for="(item, index) in basic.rankList">
                        <div class="color-white flex-ver-center rank-content" :class="['rank-content-' + (index + 1)]" v-if="item[basic.rankType]">
                            <section class="relative rank-icon">
                                <svg-icon icon-class="排名"></svg-icon>
                                <span class="rank-text">{{ index + 1 }}</span>
                            </section>
                            <section class="flex-dynamic-full flex-space-between" style="padding-left:1rem">
                                <span class="underline cursor-pointer" @click="fn.openDialogHandle(item)">{{ item.name }}</span>
                                <span>{{ item[basic.rankType] }}</span>
                            </section>
                        </div>
                    </template>
                </section>
            </section>
        </section>
        <tricomponent-dialog :show="basic.dialogShow" width="30%" :title="basic.dialogData.name"
            @onCloseDialog="fn.closeDialogHandle">
            <el-row :gutter="20">
                <template v-for="(value, key) in Columns">
                    <el-col :span="12" v-if="value.rank_column == rank_column">{{ value.label }}：{{basic.dialogData[key]}}</el-col>
                </template>
            </el-row>
        </tricomponent-dialog>
    </section>
</template>

<style lang="scss" scoped>
.rank-column-1 {
    padding-bottom: 0.8rem;
}
.rank-column-2 {
    padding-top: 0.8rem;
}
.svg-icon {
    width: 30px;
    height: 30px;
}
</style>
<script setup>
import Columns from '~/data/rt-column';
import { useOwnerStore } from '~/store/owner';

const emit = defineEmits(['update-energy-rank']);
const ownerStore = useOwnerStore();
const props = defineProps({
    data: Object,
    rank_column: [String, Number],
})
const { rank_column } = props;
const { data } = toRefs(props);
const basic = reactive({
    loading: false,
    rankType: '',
    rankTypeName: '',
    rankTypeList: [],
    rankList: [],
    rankArrow: 'down',
    dialogShow: false,
    dialogData: {},
})
const fn = {
    sortHandle(list) {
        !list && (list = basic.rankList);
        // if (basic.rankArrow == 'up') {
        //     basic.rankArrow = 'down';
        //     basic.rankList = list.sort((a, b) => parseFloat(a[basic.rankType]) - parseFloat(b[basic.rankType]));
        // } else if (basic.rankArrow == 'down') {
        //     basic.rankArrow = 'up';
        basic.rankList = list.sort((a, b) => parseFloat(b[basic.rankType]) - parseFloat(a[basic.rankType]));
        // }
    },
    openDialogHandle(info) {
        basic.dialogShow = true;
        basic.dialogData = info;
    },
    closeDialogHandle() {
        basic.dialogShow = false;
        basic.dialogData = {};
    },
    rankTypeChange(val) {
        basic.rankType = val;
        basic.rankTypeName = Columns[val].rank;
        fn.sortHandle(basic.rankList);
    },
    init(val) {
        basic.rankTypeList = Object.keys(Columns).filter(key => Columns[key].rank_column == rank_column);
        basic.rankType = basic.rankTypeList[0];
        basic.rankTypeName = Columns[basic.rankTypeList[0]].rank;
        fn.sortHandle(val);
    },
}

watch(() => ownerStore.ownerId, _ => {
    _ && fn.init(data.value);
}, {
    immediate: true,
});
watch(data, val => {
    fn.init(val)
}, {
    deep: true,
})

</script>