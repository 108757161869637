<template>
    <section class="screen-wrap flex height-full">
        <!-- <section class="screen-center-top"> -->
            <el-row class="float-energy-box" :gutter="20">
                <el-col :span="6">
                    <CountUpBox :data="basic.columns" class="float-energy-content" objkey="daily" />
                </el-col>
                <el-col :span="6">
                    <CountUpBox :data="basic.columns" class="float-energy-content" objkey="month" />
                </el-col>
                <el-col :span="6">
                    <CountUpBox :data="basic.columns" class="float-energy-content" objkey="year" />
                </el-col>
                <el-col :span="6">
                    <CountUpBox :data="basic.columns" class="float-energy-content" objkey="total" />
                </el-col>
            </el-row>
        <!-- </section> -->
        <section class="screen-left flex-direction-column">
            <section class="left-chart column-box">
                <PowerChart :data="basic.columns" />
                <EquipmentChart :data="basic.columns" />
            </section>
            <section class="left-info column-box scroll-hidden flex-dynamic-full">
                <Columns :data="basic.columns" />
            </section>
        </section>
        <section class="flex-dynamic-full">
            <section class="flex-dynamic-full flex height-full">
                <section class="screen-center flex-direction-column flex-dynamic-full">
                    <section class="center-map flex-dynamic-full">
                        <Map :ownerDetails="basic.ownerDetails" />
                    </section>
                </section>
                <section class="screen-right">
                    <Rank v-if="basic.energyDetail" :rank_column="1" :data="basic.energyDetail" class="column-box rank-column" />
                    <Rank v-if="basic.installDetail" :rank_column="2" :data="basic.installDetail" class="column-box rank-column" />
                </section>
            </section>
            <section class="bottom-chart relative">
                <!-- <EquipmentStatus :data="basic.columns" /> -->
                <PowerLineChart :data="basic.powerChartData" />
            </section>
        </section>
    </section>
</template>
<style lang="scss" scoped>
.float-energy-box {
    position: absolute;
    left: 33.2rem;
    top: 10rem;
    z-index: 10;
    white-space: nowrap;
}
</style>
<script>
import CountUpBox from './columns/count_up.vue';
import Columns from './columns/columns.vue';
import PowerLineChart from './columns/power_line_chart.vue';
import PowerChart from './columns/power_chart.vue';
import OutputChart from './columns/output_chart.vue';
import Map from './map.vue';
import EquipmentStatus from './columns/equipment.vue';
import EquipmentChart from './columns/equipment_chart.vue';
import Rank from './columns/rank.vue';

export default {
    components: {
        CountUpBox,
        Map,
        Columns,
        PowerLineChart,
        PowerChart,
        OutputChart,
        Rank,
        EquipmentChart,
        EquipmentStatus,
    }
}
</script>
<script setup>
import {
    fetchPowerChartData,
    fetchInstallData,
    fetchWeatherData,
    fetchGenerationData,
    fetchEquipmentCountData,
    fetchEnergyDetail,
    fetchInstalledDetail,
} from '~/utils/api/solar/monitor';
import { fetchOwnerLeaf } from '~/utils/api/owner';
import { useOwnerStore } from '~/store/owner';
import { useWebsocketStore } from '~/store/socket';
const router = useRouter();
const ownerStore = useOwnerStore();
const websocketStore = useWebsocketStore();
const basic = reactive({
    powerChartData: [],
    loading: true,
    columns: {},
    energyRankList: [],
    energyDetail: null,
    installDetail: null,
    ownerDetails: [],
})
const fn = {
    concatDetailData(data = []) {
        return data.reduce((c, n) => {
            c[n.id] = n;
            return c;
        }, {})
    },
    async fetchDetail() {
        const res = await Promise.all([
            fetchEnergyDetail(),
            fetchInstalledDetail(),
            fetchOwnerLeaf({
                self: true,
            }),
        ]).catch(err => {
            // fn.fetchDetail();
        })
        let e = {};
        let i = {};
        if (res[0].code == 0) {
            basic.energyDetail = res[0].data;
            e = fn.concatDetailData(res[0].data);
        }
        if (res[1].code == 0) {
            basic.installDetail = res[1].data;
            i = fn.concatDetailData(res[1].data);
        }
        if (res[2].code == 0) {
            basic.ownerDetails = res[2].data.map(item => {
                return {
                    ...item,
                    rt: {
                        ...e[item.id],
                        ...i[item.id],
                    }
                }
            })
        }
    },
    async init(params) {
        const loading = ElLoading.service();
        const res = await Promise.all([
            fetchWeatherData(params),
            fetchInstallData(params),
            fetchGenerationData(params),
            fetchEquipmentCountData(params),
            fetchPowerChartData(params),
        ]).catch(err => {
            fn.init(params);
        })
        let columns = {};
        if (res) {
            res.forEach((r, i) => {
                if (r.code == 0) {
                    if (i == 3) {
                        const list = r.data;
                        // list.unshift({
                        //     name: '全部设备',
                        //     value: r.data.reduce((c, n) => {
                        //         return c += parseFloat(n.value);
                        //     }, 0),
                        // });
                        columns = {
                            ...columns,
                            equipment: list,
                        };
                    } else if (i == 4) {
                        basic.powerChartData = r.data;
                    } else {
                        columns = {
                            ...columns,
                            ...r.data,
                            origin: {
                                ...columns.origin,
                                ...r.data.origin,
                            }
                        };
                    }
                }
            })
        }
        basic.columns = columns;
        loading.close();
    }
}
watch(() => ownerStore.ownerId, ownerId => {
    ownerId && fn.init({ownerId});
}, {
    immediate: true,
});
watch(() => websocketStore.screenLeftData, val => {
    const keys = Object.keys(val);
    const params = keys.reduce((c, n) => {
        c = {
            ...c,
            ...val[n],
        }
        return c;
    }, {})
    basic.columns = {
        ...basic.columns,
        ...params,
    }
}, {
    deep: true,
});
onMounted(() => {
    fn.fetchDetail();
})
</script>