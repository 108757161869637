<template>
    <div id="map" class="height-full relative"></div>
</template>
<script setup>
import { useOwnerStore } from '@/store/owner';
import { dynamicFrontRequest } from "@/utils/api/common";

const ownerStore = useOwnerStore();
const props = defineProps({
    ownerDetails: Array,
});
const { ownerDetails } = toRefs(props);
const basic = reactive({
    scene: null,
    districtCode: [],
    ownerList: [],
    markerLayer: null,
    pointLayer: null,
    labelLayer: null,
    lineLayer: null,
    polygonLayer: null,
})
const fn = {
    async getGeoJSON() {
        const res = await dynamicFrontRequest({
            api: "/geoJSON/100000.json",
            method: "GET",
        });
        let result = [];
        if (res.code == 0) {
            result = res.data;
        }
        return result;
    },
    async initMap() {
        basic.scene = new L7.Scene({
            id: 'map',
            logoVisible: false,
            map: new L7.Map({
                center: [103.45130428427478, 33.891355863805515],
                zoom: 3.295844580388872,
                pitch: 10,
            })
        });
        basic.scene.setMapStatus({
            dragEnable: true, // 是否允许地图拖拽
            keyboardEnable: false, // 是否允许形键盘事件
            doubleClickZoom: false, // 双击放大
            zoomEnable: true, // 滚动缩放
            rotateEnable: true, // 旋转
        });
        basic.scene.on('loaded', () => {
            fn.getGeoJSON().then(res => {
                fn.createPolygonLayer(res);
                fn.createLineLayer(res);
                fn.createLabelLayer(res);
                // fn.createMarkerLayer();
                fn.createPointLayer();
            })
        })
    },
    createLineLayer(res) {
        const layer = new L7.LineLayer({
            name: 'line',
            zIndex: 2,
        })
            .source(res)
            .color("rgb(255,255,255)")
            .size(0.2);

        basic.scene.addLayer(layer);
    },
    createLabelLayer(res) {
        const list = res.features.map(item => {
            if (item.properties.centroid) {
                return {
                    adcode: item.properties.adcode,
                    lng: item.properties.centroid[0],
                    lat: item.properties.centroid[1],
                    name: item.properties.name
                }
            }
        }).filter(Boolean);
        const layer = new L7.PointLayer({
            name: 'label',
            zIndex: 10,
        })
            .source(list, {
                parser: {
                    type: 'json',
                    x: 'lng',
                    y: 'lat',
                }
            })
            .shape('name', 'text')
            .size(12)
            .color('adcode', (value) => {
                if (basic.districtCode[value]) {
                    return 'rgb(255,0,0)';
                }
                return 'rgb(0,0,0)';

            })
            .style({
                textOffset: [0, 0],
                textAnchor: 'bottom',
                strokeWidth: 1, // 描边宽度
                strokeOpacity: 1.0,
                textAllowOverlap: true,
                // spacing: 1, // 字符间距
                // padding: [1, 1],
                // rotation: 3, // 常量旋转
                // rotation: { // 字段映射旋转
                //     field: 'name',
                //     value: [30, 270]
                // }
            })
        basic.scene.addLayer(layer);
    },
    createMarkerLayer() {
        const markerLayer = new L7.MarkerLayer({
            name: 'marker',
            zIndex: 30,
        });
        basic.ownerList.length > 0 && basic.ownerList.forEach(item => {
            const popup = new L7.Popup({
                anchor: 'bottom',
                offsets: [0, 50],
            }).setText(item.name);
            const marker = new L7.Marker({
                anchor: 'bottom',
                color: '#fffa1c',
                extData: {
                    ...item,
                }
            }).setLnglat(item.mapCenter.reverse()).setPopup(popup);
            marker.on('click', e => {
                ElMessage.info('marker: ' + e.data.name)
            });
            markerLayer.addMarker(marker);
        })
        basic.scene.addMarkerLayer(markerLayer);
    },
    createPointLayer() {
        const list = basic.ownerList.map(item => {
            return {
                lng: item.longitude,
                lat: item.latitude,
                name: item.name,
                virtual: item.virtual,
                data: item,
                id: item.id,
            }
        });
        const layer = new L7.PointLayer({
            name: 'owner',
            zIndex: 20
        })
            .source(list, {
                parser: {
                    type: 'json',
                    x: 'lng',
                    y: 'lat'
                }
            })
            .shape('circle')
            .size('id', v => {
                return v == ownerStore.ownerId ? 100 : 40;
            })
            .color('virtual', v => {
                return v ? '#ff7800' : '#fd00ff';
            })
            .active(true)
            .animate(true)
            .style({
                opacity: 0.6,
                strokeWidth: 1,
                offsets: [0, 0],
            })

        layer.on('click', function (e) {
            const popup = new L7.Popup({
                // 初始锚点经纬度
                lngLat: {
                    lng: e.feature.lng,
                    lat: e.feature.lat,
                },
                closeOnClick: true,
                closeOnEsc: true,
                offsets: [0, 20],
                // Popup 标题
                title: `
                    <div>${e.feature.name}（<span style="color:red">${e.feature.virtual ? '多用户' : '单位'}</span>）</div>
                `,
                // Popup 内容
                html: `
                    <div style="color:blue;">经度：${e.feature.lng}</div>
                    <div style="color:blue;">纬度：${e.feature.lat}</div>
                `,
            });
            basic.scene.addPopup(popup);
            ElMessage.info(`marker: ${e.feature.name}`);
        })
        basic.scene.addLayer(layer);
    },
    createPolygonLayer(res) {
        const layer = new L7.PolygonLayer({
            name: 'polygon',
            zIndex: 1,
        })
            .source(res)
            .color('adcode', (value) => {
                if (basic.districtCode[value]) {
                    return '#64c7ba';
                }
                return '#012c91';
            });
        basic.scene.addLayer(layer);
    },
    async init(val = []) {
        basic.ownerList = val;
        basic.districtCode = val.reduce((c, n) => {
            const adcode = n.adcode.substr(0, 2) + '0000';
            c[adcode] = true
            return c;
        }, {});
        fn.initMap();
    },
    destroy() {
        basic.scene && basic.scene.destroy();
    }
}
watch(() => ownerStore.ownerId, val => {
    if (basic.scene) {
        const ownerLayer = basic.scene.getLayerByName('owner');
        basic.scene.removeLayer(ownerLayer);
        fn.createPointLayer();
    }
})
watch(ownerDetails, val => {
    if (Array.isArray(val)) {
        fn.destroy();
        fn.init(val);
    }
}, {
    deep: true,
})
onUnmounted(() => {
    fn.destroy();
})
</script>
<style lang="scss" scoped>
.test-btn {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 999;

}
</style>