<template>
    <section class="monitor-box screen flex-dynamic-full scroll-hidden">
        <iffs v-if="userStore.api_info.PLATFORM_API_CATEGORY == 'iffs'" />
        <solar v-else-if="userStore.api_info.PLATFORM_API_CATEGORY == 'solar'" />
    </section>
</template>
<style lang="scss" scoped>
.info-box {
    position: relative;
}
</style>
<script>
import iffs from './iffs/index.vue';
import solar from './solar/index.vue';
export default {
    components: {
        iffs,
        solar,
    }
}
</script>

<script setup>

import { useUserStore } from "~~/store/user";
const userStore = useUserStore();

</script>