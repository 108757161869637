<template>
    <section class="power-gauge relative">
        <section class="common-inset-shadow-dark height-full flex-direction-column">
            <!-- <div class="legend-box">
                <div class="power flex-ver-center">
                    <span class="circle"></span>
                    <span>当前功率</span>
                </div>
                <div class="max-power flex-ver-center">
                    <span class="circle"></span>
                    <span>最大功率</span>
                </div>
                <div class="installed flex-ver-center">
                    <span class="circle"></span>
                    <span>总装机</span>
                </div>
            </div> -->
            <div class="total-info color-white" style="border-bottom: 1px solid #ffffff">
                <el-row :gutter="20">
                    <el-col :span="12" style="border-right: 1px solid #ffffff">
                        <div class="text-center">总装机</div>
                        <div class="font-bolder text-center">{{ data?.installed || 0 }}</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-center">最大功率</div>
                        <div class="font-bolder text-center">{{ data?.maxPower || 0 }}</div>
                    </el-col>
                </el-row>
            </div>
            <div id="power-guage-chart" class="flex-dynamic-full relative"></div>
        </section>
    </section>
</template>
<script setup>
import Decimal from 'decimal.js';
const props = defineProps({
    data: Object,
})
const { data } = toRefs(props);

const basic = reactive({
    chart: null,
})
const fn = {
    createPowerChart(info) {
        const power = parseFloat(info.origin?.power);
        const max = parseFloat(info.origin?.maxPower);
        const installed = parseFloat(info.origin?.installed);
        const percent = power / installed;
        const mpercent = max / installed;
        basic.chart = new G2Plot.Gauge('power-guage-chart', {
            percent: percent,
            range: {
                ticks: [0, 1],
                color: [`l(0) 0:#12ba12 ${percent}:#FAAD14 ${mpercent}:#F4664A 1:#FFFFFF`],
            },
            indicator: {
                pointer: {
                    style: {
                        stroke: '#D0D0D0',
                    },
                },
                pin: {
                    style: {
                        stroke: '#D0D0D0',
                    },
                },
            },
            axis: {
                label: {
                    formatter(v) {
                        if (isNaN(installed)) {
                            return '';
                        }
                        if (v == 1) {
                            return installed + 'kW';
                        }
                        return new Decimal(v * installed) + 'kW';
                    },
                },
                subTickLine: {
                    count: 3,
                },
            },
            statistic: {
                content: {
                    offsetY: 10,
                    formatter: () => `当前功率：${info.power || ''}`,
                    style: {
                        color: 'rgba(255,255,255,0.65)',
                        fontSize: 14,
                    },
                },
            },
        });

        basic.chart.render();
    },
    async init(info) {
        if (info) {
            fn.createPowerChart(info);
        }
    },
    destroyChart() {
        basic.chart && basic.chart.destroy();
    }
}
watch(data, val => {
    fn.destroyChart();
    fn.init(val);
}, {
    deep: true,
});
onMounted(() => {
    fn.init(data.value);
})
onUnmounted(() => {
    fn.destroyChart();
})
</script>
<style lang="scss" scoped>
.el-col {
    
    text-shadow: 1px 1px 3px #efefef;
}
.common-inset-shadow-dark {
    padding: 20px;
}

.legend-box {
    position: absolute;
    right: 0.4rem;
    top: 0.4rem;
    font-size: 1.2rem;

    .circle {
        width: 1.2rem;
        height: 0.2rem;
        border-radius: 0.2rem;
        margin-right: 0.2rem;
    }

    .power {
        color: #30BF78;

        .circle {
            background-color: #30BF78;
        }
    }

    .max-power {
        color: #FAAD14;

        .circle {
            background-color: #FAAD14;
        }
    }

    .installed {
        color: #ffffff;

        .circle {
            background-color: #ffffff;
        }
    }
}
</style>