<template>
    <div id="container" class="height-full relative"></div>
</template>
<style scoped>
    a{}
</style>
<script setup>
import { dynamicFrontRequest } from "@/utils/api/common";
// const t = useNuxtApp();
// const L7 = t.$L7;
// // const L7plot = t.$L7plot;
// const L7Maps = t.$L7Maps;
// console.log(L7);
// // console.log(L7plot);
// console.log(L7Maps);

const fn = {
    // async getGeoJSON() {
    //     const res = await dynamicFrontRequest({
    //         api: "/geoJSON/110000.json",
    //         method: "GET",
    //     });
    //     let result = [];
    //     if (res.code == 0) {
    //         result = res.data;
    //     }
    //     return result;
    // },
    // async init() {
    //     const scene = new L7.Scene({
    //         id: 'container',
    //         map: new L7Maps.Mapbox({ 
    //             style: 'blank',
    //             center: [ 116.4, 40.15 ],
    //             pitch: 1,
    //             zoom: 9.5,
    //         })
    //     });
    //     fn.getGeoJSON().then(res => {
    //         console.log(res);
    //         // fn.createPolygonLayer(scene);
    //         fn.createImageLayer(scene);
    //     })
    // },
    // createImageLayer(scene) {
    //     const layer = new L7.ImageLayer().source('/images/test.png',
    //         {
    //             parser: {
    //                 type: 'image',
    //                 extent: [116, 40, 116.8, 40.3],
    //             },
    //         },
    //     )
    //     console.log(layer)
    //     scene.addLayer(layer);
    // },
    // createPolygonLayer(scene) {
    //     const layer = new L7.PolygonLayer().source(res).color('name', [
    //         'rgb(239,243,255)',
    //         'rgb(189,215,231)',
    //         'rgb(107,174,214)',
    //         'rgb(49,130,189)',
    //         'rgb(8,81,156)',
    //     ])
    //     scene.addLayer(layer);
    // }
}

onMounted(() => {
    // if (process.client) {
    //     fn.init();
    // }
})
</script>
