<template>
    <section class="height-full" style="padding-top:1.6rem">
        <section class="height-full scroll-auto-y common-inset-shadow-dark flex-direction-column">
            <template v-for="item in basic.list">
                <div class="color-white text clearfix flex-dynamic-full flex width-full">
                    <section class="flex-ver-center">
                        <svg-icon :icon-class="item.svg"></svg-icon>
                        <span class="column-text">{{ item.label }}</span>
                    </section>
                    <section class="flex-ver-center flex-dynamic-full" style="justify-content: flex-end">
                        <template v-if="item.click">
                            <span class="shadow column-text underline cursor-pointer" @click="fn.openDialogHandle(item)">{{ data?.[item.key] }}</span>
                        </template>
                        <template v-else>
                            <span class="shadow column-text">{{ data?.[item.key] }}</span>
                        </template>
                    </section>
                </div>
            </template>
        </section>
        <tricomponent-dialog :show="basic.dialogShow" :width="basic.dialogWidth" :title="basic.dialogTitle" @onCloseDialog="fn.closeDialogHandle">
            <InjectScreenLineChart v-if="basic.dialogType == 'line'" :data="basic.dialogData" :summary="basic.dialogSummary" />
        </tricomponent-dialog>
    </section>
</template>
<script setup>
import Columns from '~/data/rt-column';

const props = defineProps({
    data: Object,
})
const { data } = toRefs(props);
const basic = reactive({
    list: Object.keys(Columns).filter(key => Columns[key].column).map(key => ({
        key,
        ...Columns[key],
    })),
    dialogShow: false,
    dialogData: null,
    dialogType: '',
    dialogSummary: '',
    dialogWidth: '80%',
    dialogTitle: '',
})
const fn = {
    openDialogHandle(item) {
        if (item.click.chart) {
            basic.dialogTitle = item.click.title;
            basic.dialogType = item.click.chart;
            basic.dialogSummary = item.key;
            basic.dialogData = item;
            basic.dialogShow = true;
        }
    },
    closeDialogHandle() {
        basic.dialogShow = false;
        basic.dialogData = null;
    },
}
</script>
<style lang="scss" scoped>
.common-inset-shadow-dark {
    padding: 1rem;
}

.text {
    font-size: 1.4rem;
    padding: 0.2rem 0.5rem;
    .shadow {
        text-shadow: 2px 2px 8px #ffffff;
    }
    .svg-icon {
        width: 2rem;
        height: 2rem;
        fill: #ffffff;
        margin-right: 1.4rem;
    }
    .column-text {
        font-size: 1.6rem;
    }
}
</style>