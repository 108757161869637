<template>
    <layout-screen>
        <SandTable />
    </layout-screen>
</template>

<style lang="scss" scoped>
.empty-box {}
</style>

<script>
import SandTable from '~/views/screen/index.vue';

export default {
    components: {
        SandTable,
    }
}
</script>

<script setup>

definePageMeta({
    menu: 'screen',
})
</script>
